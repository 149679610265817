<template>
  <span>
    <b-button class="mr-2" variant="outline-primary" size="sm" @click="showHistory">history</b-button>
    <b-modal v-model="historyModal" size="xl" scrollable ok-only title="Obligation history">
      <div v-if="history.length > 0">
        <div class="mb-4" v-for="version in history" :key="'history-' + version.id">
          <b-container class="my-4 py-4 border-bottom">
            <b-row>
              <b-col>
                <span style="font-size: 1.25em" v-if="version.obligationtype"><b-badge :variant="badgeVariant">
                  {{version.obligationtype.name}}</b-badge></span> citation:
                  <history-markup :current="annotation.citation" :version="version.citation"></history-markup>
                  | id: {{version.id}}
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="version.topic">
              <b-col>
                <div v-if="version.topic"><strong><history-markup :current="annotation.topic" :version="version.topic"></history-markup></strong></div>
                <div><strong style="font-size: 1.25em"><history-markup :current="annotation.name" :version="version.name"></history-markup></strong></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.name">
              <b-col>
                <div><strong>Name</strong></div>
                <div><history-markup :current="annotation.name" :version="version.name"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.text">
              <b-col>
                <div><strong>regulatory text</strong></div>
                <div><history-markup :current="annotation.text" :version="version.text"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <div><strong>description</strong></div>
                <div><history-markup :current="annotation.description" :version="version.description"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.context">
              <b-col>
                <div><strong>context</strong></div>
                <div><history-markup :current="annotation.context" :version="version.context"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.standard">
              <b-col>
                <div><strong>minimum standard</strong></div>
                <div><history-markup :current="annotation.standard" :version="version.standard"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.lagging">
              <b-col>
                <div><strong>lagging market practice</strong></div>
                <div><history-markup :current="annotation.lagging" :version="version.lagging"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.market_practice">
              <b-col>
                <div><strong>leading market practice</strong></div>
                <div><history-markup :current="annotation.market_practice" :version="version.market_practice"></history-markup></div>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="annotation.date_effective">
              <b-col>
                <div><strong>effective date</strong></div>
                <div><history-markup :current="moment(annotation.date_effective).format('YYYY-MM-DD')" :version="moment(version.date_effective).format('YYYY-MM-DD')"></history-markup></div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import HistoryMarkup from '@/components/HistoryMarkup.vue'

export default {
  name: 'AnnotationHistoryModal',
  props: ['annotation'],
  components: {
    HistoryMarkup
  },
  computed: {
    badgeVariant: function () {
      let res = 'primary'
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          res = 'danger'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'entity') {
          res = 'success'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'context') {
          res = 'info'
        }
      }
      return res
    }
  },
  data () {
    return {
      history: [],
      historyModal: false,
      moment: moment
    }
  },
  methods: {
    showHistory: async function () {
      try {
        this.history = await this.$Amplify.API.get('cosmos', `/obligation/${this.annotation.id}/history`)
        this.historyModal = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    }
  }
}
</script>

<style scoped>
</style>
