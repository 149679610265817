<template>
  <div>
    <div class="mb-2"><strong class="mr-2">{{name}}</strong>
      <b-button class="mr-2" v-if="editable" variant="outline-primary" size="sm" v-on:click="edit = true">edit</b-button>
    </div>
    <div class="mb-2">
      <span v-if="!loading">
        <div v-for="value in values" :key="value.id">
          <span v-if="linkable">
            <router-link :to="{ name: linkName, params: { id: value.id }}">
              {{value.name}}
            </router-link>
          </span>
          <span v-if="!linkable">
            {{value.name}}
          </span>
          <b-button v-if="edit || editAlwaysOn" variant="outline-primary" size="sm" v-on:click="deleteEdge(value)">remove</b-button>
        </div>
      </span>
      <span v-if="loading">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </span>
    </div>
    <div v-if="edit || editAlwaysOn">
      <span v-if="!busy">
        <b-form-select class="mb-2" v-model="selected" :options="options" />
        <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="saveEdge">save</b-button>
        <b-button v-if="!editAlwaysOn" class="mr-2" variant="outline-primary" size="sm" v-on:click="edit = false">done</b-button>
      </span>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SelectList',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    editAlwaysOn: {
      type: Boolean,
      default: false
    },
    labelLinkingField: String,
    linkingModel: String,
    parentId: Number,
    parentLinkingField: String,
    linkable: {
      type: Boolean,
      default: false
    },
    linkName: {
      type: String,
      default: ''
    },
    name: String,
    valuesProp: Array,
    verticesProp: Array
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.selected
    }
  },
  created: function () {
    this.values = this.valuesProp
    this.buildOptions()
    this.loading = false
  },
  data () {
    return {
      busy: false,
      edit: false,
      loading: true,
      options: [],
      selected: '',
      values: [],
      vertices: []
    }
  },
  methods: {
    buildOptions: function () {
      this.vertices = this.verticesProp
      this.$logger.debug('got vertices', this.vertices)
      let optionsTemp = []
      optionsTemp[0] = { value: null, text: 'select' }
      for (let i = 0, len = this.vertices.length; i < len; i++) {
        optionsTemp[i + 1] = { value: this.vertices[i].id, text: this.vertices[i].name }
      }
      if (this.values.length > 0) {
        for (let i = 0, len = this.values.length; i < len; i++) {
          _.remove(optionsTemp, _.matchesProperty('value', this.values[i].id))
        }
      }
      this.options = _.sortBy(optionsTemp, ['name'])
      this.selected = null
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/relationship/${this.linkingModel}`
        let params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: this.labelLinkingField, id: value.id }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$stat.log({ action: 'unlinked property', model: this.linkingModel, payload: params.body })
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    saveEdge: async function () {
      this.$logger.debug(`saveEdge start`)
      this.busy = true
      try {
        // let response = await this.$http(config)
        const apiName = 'cosmos'
        const path = `/standard/relationship/${this.linkingModel}`
        const params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: this.labelLinkingField, id: this.selected },
            username: this.user.username
          }
        }
        const response = await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ action: 'linked new property', model: this.linkingModel, model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        const newValue = _.find(this.options, ['value', this.selected])
        this.values.push({ id: newValue.value, name: newValue.text })
        this.selected = null
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.busy = false
    }
  },
  watch: {
    verticesProp: function () {
      this.buildOptions()
    }
  }
}
</script>

<style>
</style>
