<template>
<span v-if="!busy">
  <b-alert :show="showDeleted">Deleted</b-alert>
  <span v-if="!showDeleted">
  <b-btn :class="visible ? null : 'collapsed'" variant="danger" @click="visible = !visible">delete</b-btn>
  <b-collapse v-model="visible" class="mt-2">
    <b-card>
      <strong>
        <div>It will not be possible to recover the item once deleted</div>
        <div>Are you sure you want to delete?</div>
      </strong>
        <b-btn class="mr-5" variant="danger" @click="deleteStandard">yes, delete</b-btn>
        <b-btn class="ml-5" variant="success" @click="visible = !visible">cancel</b-btn>
    </b-card>
  </b-collapse>
  </span>
</span>
</template>

<script>
export default {
  name: 'DeleteStandard',
  props: ['id', 'model'],
  data () {
    return {
      busy: false,
      showDeleted: false,
      visible: false
    }
  },
  methods: {
    deleteStandard: async function () {
      this.busy = true
      try {
        let apiName = 'cosmos'
        let path = `/standard/${this.model}/${this.id}`
        let response = await this.$Amplify.API.del(apiName, path)
        this.showDeleted = true
        if (response.success) {
          this.$emit('deleted')
        }
      } catch (e) {
        this.$logger.warn('error', e)
      }
      this.busy = false
    }
  }
}
</script>
