<template>
  <span v-html="rendered"></span>
</template>

<script>
export default {
  name: 'NewLines',
  props: ['content'],
  computed: {
    rendered: function () {
      let c = ' '
      if (this.content) {
        c = this.content
      }
      return c.replace(/(\r\n|\n|\r)/gm, "<br />")
    }
  }
}
</script>

<style>
</style>
